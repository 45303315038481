<template>
  <v-row>
  <v-col cols="8">
    <v-card>
      <v-item-group v-model="currentStep" class="text-center justify-content-center" mandatory>
        <v-item v-slot="{ active }">
          <v-btn
            dense
            text
            tile
            :style="{
              opacity: active ? 1 : 0.8,
              'border-bottom': currentStep >= 0 ? '5px solid green' : 'none' ,
              width: 'auto',
              padding: '0 0.5rem',
            }"
            @click="setCurrentStepAndStatus(0)"
          >
            1 - Projeto
            <v-icon
              v-if="(active || currentStep >= 0)"
              class="ml-3"
              small
              color="green"
            >
              mdi-lock-open
            </v-icon>
          </v-btn>
        </v-item>
        <v-item v-slot="{ active }">
          <v-btn
            dense
            text
            tile
            :style="{
              opacity: active ? 1 : 0.8,
              'border-bottom': currentStep >= 1 ? '5px solid green' : 'none' ,
              width: 'auto',
              padding: '0 0.5rem',
            }"
            :disabled="projeto.status <= StatusProjetoEnum.EM_DEFINICAO_PROJETO && projeto.status != StatusProjetoEnum.ATIVO"
            @click="setCurrentStepAndStatus(1)"
          >
            2 - Pareamento de rubricas
            <v-icon
              v-if="(!active  &&  currentStep <= 1) && (projeto.status < StatusProjetoEnum.EM_PAREAMENTO && projeto.status != StatusProjetoEnum.ATIVO)"
              class="ml-3"
              small
              color="#999"
            >
              mdi-lock
            </v-icon>
            <v-icon
              v-if="(active || projeto.status >= StatusProjetoEnum.EM_PAREAMENTO) || projeto.status == StatusProjetoEnum.ATIVO"
              class="ml-3"
              small
              color="green"
            >
              mdi-lock-open
            </v-icon>
          </v-btn>
        </v-item>
        <v-item v-slot="{ active }">
          <v-btn
            dense
            text
            tile
            :style="{
              opacity: active ? 1 : 0.8,
              'border-bottom': currentStep >= 2 ? '5px solid green' : 'none' ,
              width: 'auto',
              padding: '0 0.5rem',
            }"
            :disabled="projeto.status <= StatusProjetoEnum.EM_PAREAMENTO && projeto.status != StatusProjetoEnum.ATIVO"
            @click="setCurrentStepAndStatus(2)"
          >
            3 - Parametrização
            <v-icon
              v-if="(!active  &&  currentStep <= 2) && (projeto.status < StatusProjetoEnum.EM_PARAMETRIZACAO && projeto.status != StatusProjetoEnum.ATIVO)"
              class="ml-3"
              small
              color="#999"
            >
              mdi-lock
            </v-icon>
            <v-icon
              v-if="(active || projeto.status >= StatusProjetoEnum.EM_PARAMETRIZACAO) || projeto.status == StatusProjetoEnum.ATIVO"
              class="ml-3"
              small
              color="green"
            >
              mdi-lock-open
            </v-icon>
          </v-btn>
        </v-item>
        <v-item v-slot="{ active }">
          <v-btn
            dense
            text
            tile
            :style="{
              opacity: active ? 1 : 0.8,
              'border-bottom': currentStep >= 3 ? '5px solid green' : 'none' ,
              width: 'auto',
              padding: '0 0.5rem',
            }"
            :disabled="projeto.status <= StatusProjetoEnum.EM_PARAMETRIZACAO && projeto.status != StatusProjetoEnum.ATIVO"
            @click="setCurrentStepAndStatus(3)"
          >
            4 - Orçamento
            <v-icon
              v-if="(!active  &&  currentStep <= 3) && (projeto.status < StatusProjetoEnum.EM_DETALHAMENTO && projeto.status != StatusProjetoEnum.ATIVO)"
              class="ml-3"
              small
              color="#999"
            >
              mdi-lock
            </v-icon>
            <v-icon
              v-if="(active || projeto.status >= StatusProjetoEnum.EM_DETALHAMENTO) || projeto.status == StatusProjetoEnum.ATIVO"
              class="ml-3"
              small
              color="green"
            >
              mdi-lock-open
            </v-icon>
          </v-btn>
        </v-item>
      </v-item-group>
      <v-window v-model="currentStep">
        <v-window-item>
          <v-row>
            <v-col class="ml-3">
              <v-card-title>
                {{ !isEdicaoProjeto ? 'Ficha de inclusão de projeto de captação' : 'Edição projeto captação'}}
              </v-card-title>
              <v-card-text>
                <v-row class="ml-n4 mr-0">
                  <input-v
                    v-for="(field, index) of cols"
                    class="pl-4"
                    :colSize="field.colSize"
                    :key="index"
                    :label="field"
                    :opts="opts"
                    :valid.sync="field.valid"
                    v-model="projeto[field.key]"
                    @changed="changedField(field.key, $event)"
                    @input="changedField(field.key, $event)"
                  ></input-v>
                </v-row>
              </v-card-text>
            </v-col>
          </v-row>
        </v-window-item>
        <!-- <v-window-item :eager="isEdicaoProjeto && (projeto.status >= StatusProjetoEnum.EM_PAREAMENTO || projeto.status < StatusProjetoEnum.EM_DEFINICAO_PROJETO)"> -->
        <v-window-item :eager="isEdicaoProjeto">
          <DescricaoDispendios :disabled="isAtivo || isFinalizado || isCancelado"></DescricaoDispendios>
        </v-window-item>
        <!-- <v-window-item :eager="isEdicaoProjeto && (projeto.status >= StatusProjetoEnum.EM_PARAMETRIZACAO  || projeto.status < StatusProjetoEnum.EM_DEFINICAO_PROJETO)"> -->
        <v-window-item>
          <ParametrizacaoOrcamento :disabled="isAtivo || isFinalizado || isCancelado"></ParametrizacaoOrcamento>
        </v-window-item>
        <!-- <v-window-item :eager="isEdicaoProjeto && (projeto.status >= StatusProjetoEnum.EM_DETALHAMENTO  || projeto.status < StatusProjetoEnum.EM_DEFINICAO_PROJETO)"></v-window-item> -->
        <v-window-item>
          <OrcamentoDetalhado :disabled="isAtivo || isFinalizado || isCancelado"></OrcamentoDetalhado>
        </v-window-item>
      </v-window>
    </v-card>
  </v-col>
  <v-col class="mr-3">
    <v-card class="p-3">
      <v-card-title style="font-size: initial;">Responsáveis FINEP</v-card-title>
      <v-card-text class="mt-n4 mb-n12">
        <v-row class="ml-n4 mr-0">
          <v-col cols="12">
            <div class="d-flex flex-row">
              <div class="d-flex flex-column">
                <div v-if="responsavelFinanceiroFinep.id == 0">
                  <v-card class="mb-4">
                    <v-card-text>
                      <input-v
                        v-for="(field, index) of colsResponsavelFinanceiroFinep"
                        :colSize="field.colSize"
                        :key="index"
                        :label="field"
                        :opts="opts"
                        :valid.sync="field.valid"
                        v-model="responsavelFinanceiroFinep[field.key]"
                      ></input-v>
                    </v-card-text>
                    <v-card-actions>
                      <v-btn class="mt-n4" depressed color="primary" @click="saveResponsavelFinanceiroFinep()">Salvar</v-btn>
                    </v-card-actions>
                  </v-card>
                </div>
                <div v-else>
                  <v-input
                    prepend-icon="mdi-phone"
                    :messages="responsavelFinanceiroFinep.telefone"
                  >
                    Telefone
                  </v-input>
                  <v-input
                    prepend-icon="mdi-mail"
                    :messages="responsavelFinanceiroFinep.email"
                    class="my-1"
                  >
                    E-mail
                  </v-input>
                </div>
              </div>
            </div>
            <div class="d-flex flex-column">
              <v-input>
                <v-select
                  v-model="projeto['analista_financeiro_finep_id']"
                  :items="opts.responsaveisFinep"
                  item-text="nome"
                  item-value="id"
                  label="Responsável financeiro"
                  :disabled="isCancelado || isFinalizado || isAtivo"
                  @change="responsavelFinanceiroChanged($event)"
                ></v-select>
              </v-input>
            </div>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-text class="mt-n8 mb-n1">
        <v-row class="ml-n4 mr-0">
          <v-col cols="12">
            <div class="d-flex flex-row">
              <div class="d-flex flex-column">
                <div v-if="responsavelTecnicoFinep.id == 0">
                    <input-v
                      v-for="(field, index) of colsResponsavelTecnicoFinep"
                      :colSize="field.colSize"
                      :key="index"
                      :label="field"
                      :opts="opts"
                      :valid.sync="field.valid"
                      v-model="responsavelTecnicoFinep[field.key]"
                    ></input-v>
                    <v-btn class="mb-2 justify-content-end" depressed color="primary" @click="saveResponsavelTecnicoFinep()">Salvar</v-btn>
                </div>
                <div v-else>
                  <v-input
                    prepend-icon="mdi-phone"
                    :messages="responsavelTecnicoFinep.telefone"
                  >
                    Telefone
                  </v-input>
                  <v-input
                    prepend-icon="mdi-mail"
                    :messages="responsavelTecnicoFinep.email"
                    class="my-1"
                  >
                    E-mail
                  </v-input>
                </div>
              </div>
            </div>
            <div class="d-flex flex-column">
              <v-select
                v-model="projeto['analista_tecnico_finep_id']"
                :items="opts.responsaveisFinep"
                item-text="nome"
                item-value="id"
                label="Responsável técnico"
                :disabled="isCancelado || isFinalizado || isAtivo"
                @change="responsavelTecnicoChanged($event)"
              ></v-select>
            </div>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card>
        <v-card-actions class="d-flex justify-content-between">
          <v-btn color="secondary" depressed @click="currentStep--" :disabled="currentStep == 0">
            <v-icon>mdi-chevron-left</v-icon>Voltar
          </v-btn>
          <v-btn color="primary"
            depressed
            @click="doSave(false)"
            v-if="currentStep != 3 &&
              (projeto.status != StatusProjetoEnum.CANCELADO && projeto.status != StatusProjetoEnum.FINALIZADO)">
            Avançar
            <v-icon>mdi-chevron-right</v-icon>
          </v-btn>
          <v-btn
            color="primary"
            depressed
            @click="isCancelado || isFinalizado ? doFinalizeProject() :  isVisibleModalProjectWarning = true"
            v-if="currentStep == 3 ||
              (projeto.status == StatusProjetoEnum.CANCELADO || projeto.status == StatusProjetoEnum.FINALIZADO)">
            {{  isCancelado || isFinalizado ? 'Encerrar' : 'Concluir' }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-card>
  </v-col>

  <GModal
    :opened="isVisibleModalProjectWarning"
    title="Deseja continuar ?"
    >
    <slot>
      <p> Tem certeza que deseja concluir o projeto? </p>
      <p> Essa ação <b>bloqueará</b> a edição do projeto e deixará o projeto habilitado apenas para consultas</p>
    </slot>
    <template v-slot:buttons="{  }">
        <v-spacer></v-spacer>
        <v-btn
          class="px-5"
          color="secondary"
          depressed
          @click="closeModal()"
          >
          Cancelar
        </v-btn>
        <v-btn
          class="px-5"
          color="primary"
          depressed
          @click="doConcludeProject()"
          >Confirmar
        </v-btn>
        <v-spacer></v-spacer>
    </template>
  </GModal>
</v-row>
</template>

<script>
import { mapGetters } from 'vuex';
import inputV from '@/components/input-v.vue';
import * as moment from 'moment';

const StatusProjetoEnum = {
  EM_DEFINICAO_PROJETO: 4,
  EM_PAREAMENTO: 5,
  EM_PARAMETRIZACAO: 6,
  EM_DETALHAMENTO: 7,
  ATIVO: 1,
  CANCELADO: 2,
  FINALIZADO: 3,
};

export default {
    // props: {},
    components: {
      inputV,
      DescricaoDispendios: () => import('../descricao-dispendios/descricao-dispendios.vue'),
      ParametrizacaoOrcamento: () => import('../orcamentos/parametrizacao-orcamento.vue'),
      OrcamentoDetalhado: () => import('../orcamentos/orcamento-detalhado.vue'),
      GModal: () => import('@/components/g-modal.vue'),
    },
    // mixins: [],
    // directives: {},
     data() {
         return {
          projeto: {
            id: null,
            nro_contrato: '',
            titulo: '',
            data_inicio: '',
            data_fim: '',
            status: 4,
            responsavel_tecnico: '',
            responsavel_financeiro: '',
            data_protocolo: null,
            data_assinatura: null,
            periodo_retroativo: null,
            percentual_comprovacao: null,
            referencia_contrato: '',
            analista_financeiro_galapos_id: null,
            gestor_galapos_id: null,
            consultor_galapos_id: null,
            analista_financeiro_finep_id: 0,
            analista_tecnico_finep_id: 0,
            meses: 0,
          },
          opts: {
            status: [
              {
                id: 1,
                name: 'Ativo',
                disabled: this.isCancelado || this.isFinalizado,
              },
              {
                id: 2,
                name: 'Cancelado',
                disabled: this.isAtivo,
              },
              {
                id: 3,
                name: 'Finalizado',
                disabled: this.isAtivo,
              },
              {
                id: 4,
                name: 'Em definição do projeto',
                disabled: !this.isCancelado && !this.isFinalizado && !this.isAtivo,
              },
              {
                id: 5,
                name: 'Em pareamento de rubricas',
                disabled: !this.isCancelado && !this.isFinalizado && !this.isAtivo,
              },
              {
                id: 6,
                name: 'Em parametrização do orçamento',
                disabled: !this.isCancelado && !this.isFinalizado && !this.isAtivo,
              },
              {
                id: 7,
                name: 'Em detalhamento orçamento',
                disabled: !this.isCancelado && !this.isFinalizado && !this.isAtivo,
              },
            ],
            responsaveisFinep: [],
            consultoresGalapos: [],
          },
          responsavelFinanceiroFinep: {
            id: -1,
            nome: '',
            telefone: '',
            email: '',
            tipo: 'F'
          },
          responsavelTecnicoFinep: {
            id: -1,
            nome: '',
            telefone: '',
            email: '',
            tipo: 'T'
          },
          currentView: 0,
          currentStep: 0,
          StatusProjetoEnum: {
            EM_DEFINICAO_PROJETO: 4,
            EM_PAREAMENTO: 5,
            EM_PARAMETRIZACAO: 6,
            EM_DETALHAMENTO: 7,
            ATIVO: 1,
            CANCELADO: 2,
            FINALIZADO: 3,
          },
          isVisibleModalProjectWarning: false,
        }
     },
    computed: {
      ...mapGetters(['clientId']),
      isEdicaoProjeto() {
        return (this.$route.query.projetoId || this.projeto.id ) ? true : false;
      },
      projetoId() {
        return this.$route.query.projetoId || this.projeto.id;
      },
      canEdit() {
        return true;
      },
      isCancelado() {
        return this.projeto.status == this.StatusProjetoEnum.CANCELADO
      },
      isFinalizado() {
        return this.projeto.status == this.StatusProjetoEnum.FINALIZADO
      },
      isAtivo() {
        return this.projeto.status == this.StatusProjetoEnum.ATIVO
      },
      cols() {
        return [
          {
            key: 'referencia_contrato',
            name: 'Referência do contrato',
            type: this.$fieldTypes.TEXT,
            rules: [{ rule: 'required' }],
            editable: !this.isCancelado && !this.isFinalizado && !this.isAtivo,
            colSize: 6,
          },
          {
            key: 'nro_contrato',
            name: 'Nº do contrato',
            type: this.$fieldTypes.TEXT,
            rules: [{ rule: 'required' }],
            editable: !this.isCancelado && !this.isFinalizado && !this.isAtivo,
            colSize: 6,
          },
          {
            key: 'titulo',
            name: 'Nome do projeto',
            type: this.$fieldTypes.TEXT,
            rules: [{ rule: 'required' }],
            editable: !this.isCancelado && !this.isFinalizado && !this.isAtivo,
            colSize: 6,
          },
          {
            key: 'status',
            name: 'Status',
            type: this.$fieldTypes.SELECT,
            rel: { to: 'status', key: 'id', name: 'name' },
            rules: [{ rule: 'required' }],
            editable: this.projeto.status < StatusProjetoEnum.EM_DEFINICAO_PROJETO,
            colSize: 6,
          },
          {
            key: 'data_protocolo',
            name: 'Protocolo do projeto',
            type: this.$fieldTypes.DATE,
            rules: [{ rule: 'required' }],
            editable: !this.isCancelado && !this.isFinalizado && !this.isAtivo,
            colSize: 6,
          },
          {
            key: 'periodo_retroativo',
            name: 'Período retroativo',
            type: this.$fieldTypes.DATE,
            rules: [{ rule: 'required' }],
            editable: !this.isCancelado && !this.isFinalizado && !this.isAtivo,
            colSize: 6,
          },
          {
            key: 'data_assinatura',
            name: 'Assinatura do contrato',
            type: this.$fieldTypes.DATE,
            rules: [{ rule: 'required' }],
            editable: !this.isCancelado && !this.isFinalizado  && !this.isAtivo,
            colSize: 6,
          },
          {
            key: 'meses',
            name: 'Nº de meses do contrato',
            type: this.$fieldTypes.NUMBER,
            rules: [{ rule: 'required' }],
            editable: !this.isCancelado && !this.isFinalizado && !this.isAtivo,
            colSize: 6,
          },
          {
            key: '',
            name: 'Galapos',
            type: this.$fieldTypes.SUBTITLE,
          },
          {
            key: 'analista_financeiro_galapos_id',
            name: 'Analista financeiro',
            type: this.$fieldTypes.AUTOCOMPLETE,
            rel: { to: 'consultoresGalapos', key: 'id', name: 'nome'},
            editable: !this.isCancelado && !this.isFinalizado && !this.isAtivo,
            colSize: 4,
          },
          {
            key: 'gestor_galapos_id',
            name: 'Gestor',
            type: this.$fieldTypes.AUTOCOMPLETE,
            rel: { to: 'consultoresGalapos', key: 'id', name: 'nome'},
            editable: !this.isCancelado && !this.isFinalizado && !this.isAtivo,
            colSize: 4,
          },
          {
            key: 'consultor_galapos_id',
            name: 'Consultor',
            type: this.$fieldTypes.AUTOCOMPLETE,
            rel: { to: 'consultoresGalapos', key: 'id', name: 'nome'},
            editable: !this.isCancelado && !this.isFinalizado && !this.isAtivo,
            colSize: 4,
          },
        ]
      },
      colsResponsavelFinanceiroFinep() {
        return [
          {
            key: 'nome',
            name: 'Nome',
            type: this.$fieldTypes.TEXT,
            editable: !this.isCancelado && !this.isFinalizado && !this.isAtivo,
            colSize: 12,
          },
          {
            key: 'telefone',
            name: 'Telefone',
            type: this.$fieldTypes.TELEPHONE,
            editable: !this.isCancelado && !this.isFinalizado && !this.isAtivo,
            colSize: 12,
          },
          {
            key: 'email',
            name: 'Email',
            type: this.$fieldTypes.TEXT,
            editable: !this.isCancelado && !this.isFinalizado && !this.isAtivo,
            colSize: 12,
          },
        ]
      },
      colsResponsavelTecnicoFinep() {
        return [
          {
            key: 'nome',
            name: 'Nome',
            type: this.$fieldTypes.TEXT,
            editable: !this.isCancelado && !this.isFinalizado && !this.isAtivo,
            colSize: 12,
          },
          {
            key: 'telefone',
            name: 'Telefone',
            type: this.$fieldTypes.TELEPHONE,
            editable: !this.isCancelado && !this.isFinalizado && !this.isAtivo,
            colSize: 12,
          },
          {
            key: 'email',
            name: 'Email',
            type: this.$fieldTypes.TEXT,
            editable: !this.isCancelado && !this.isFinalizado && !this.isAtivo,
            colSize: 12,
          },
        ]
      },
      resourceUrl() {
        return this.apiResource(`/v1/captacao/projetos/${this.clientId}`.concat(this.projetoId ? '/' + this.projetoId : ''));
      },
      resourceResponsaveisFinep() {
        return this.apiResource(`/v1/captacao/responsaveis-finep`)
      }
    },
    // filters: {},
    created() {
      this.getResponsaveisFinep();
      this.alteraTituloFormulario();
      this.getConsultores();
      if(this.isEdicaoProjeto) {
        this.doLoadEdicao();
      }
    },
    // mounted() {},
    // updated() {},
    // destroyed() {},
     methods: {
      alteraTituloFormulario() {
        return this.isEdicaoProjeto ? this.$route.meta.pageTitle = 'Edição projeto captação' : this.$route.meta.pageTitle = 'Cadastro projeto captação';
      },
      doExitSave() {
        this.$router.go(-1);
      },
      doFinalizeProject() {
        const resource = this.resourceUrl;
        return resource.save({ ...this.projeto } ).then((response) => {
          this.doExitSave()
          return response;
        });
      },
      saveResponsavelTecnicoFinep() {
        this.responsavelTecnicoFinep['tipo'] = 'T';
        this.resourceResponsaveisFinep.save({ ...this.responsavelTecnicoFinep }).then((response) => {
          response.telefone = response.telefone.toString();
          this.opts.responsaveisFinep.push(response);
          this.projeto.analista_tecnico_finep_id = response?.id;
          this.responsavelTecnicoFinep = response;
          this.getResponsaveisFinep();
          this.responsavelTecnicoChanged(response.id);
        })
      },
      saveResponsavelFinanceiroFinep() {
        this.responsavelFinanceiroFinep['tipo'] = 'F';
        this.resourceResponsaveisFinep.save({ ...this.responsavelFinanceiroFinep }).then((response) => {
          response.telefone = response.telefone.toString();
          this.opts.responsaveisFinep.push(response);
          this.projeto.analista_financeiro_finep_id = response?.id;
          this.responsavelFinanceiroFinep = response;
          this.getResponsaveisFinep();
          this.responsavelFinanceiroChanged(response.id);
        })
      },
      doLoadEdicao() {
        this.resourceUrl.get().then((response) => {
          this.projeto = response;
          this.getResponsaveisFinep();
          this.responsavelFinanceiroChanged(this.projeto.analista_financeiro_finep_id)
          this.responsavelTecnicoChanged(this.projeto.analista_tecnico_finep_id)
        })
      },
      setCurrentStepAndStatus(step) {
        this.currentStep = step;
      },
      chooseStatus(step){
        switch(step) {
          case 0: return StatusProjetoEnum.EM_DEFINICAO_PROJETO;
          case 1: return StatusProjetoEnum.EM_PAREAMENTO;
          case 2: return StatusProjetoEnum.EM_PARAMETRIZACAO;
          case 3: return StatusProjetoEnum.EM_DETALHAMENTO;
        }
      },
      changedField(field, $event) {
        if(field == 'data_protocolo') {
          const newDate = moment($event).subtract(180, 'days').format('YYYY-MM-DD')
          this.projeto.periodo_retroativo = newDate;
        }
      },
      getConsultores() {
        const resource =  this.apiResource(`/v1/consultores`);
        resource.get({ query: `empresaId=${this.clientId}` }).then((result) => {
          return this.opts.consultoresGalapos = result.consultores;
        })
      },
      getResponsaveisFinep() {
        const resource = this.apiResource(`/v1/captacao/responsaveis-finep/selecao`);
        resource.get().then((result) => {
          const novoResponsavel = {
            id: 0,
            nome: 'Criar novo responsável',
          };
          this.opts.responsaveisFinep = result;
          this.opts.responsaveisFinep.unshift(novoResponsavel);
          return result;
        });
      },
      responsavelFinanceiroChanged(responsavelId) {
        if(responsavelId == null) return;
        const responsavel = this.opts.responsaveisFinep.filter((resp) => resp.id == responsavelId)[0];
        this.responsavelFinanceiroFinep = responsavel != null ? responsavel : this.responsavelFinanceiroFinep;
        this.projeto.analista_financeiro_finep_id = responsavel?.id;
      },
      responsavelTecnicoChanged(responsavelId) {
        if(responsavelId == null) return;
        const responsavel = this.opts.responsaveisFinep.filter((resp) => resp.id == responsavelId)[0];
        this.responsavelTecnicoFinep = responsavel != null ? responsavel : this.responsavelFinanceiroFinep;
        this.projeto.analista_tecnico_finep_id = responsavel?.id;
      },
      doSave() {
        const resource = this.resourceUrl;
        const that = this;
        this.projeto.status = this.projeto.status == this.StatusProjetoEnum.ATIVO ? 1 : this.chooseStatus(this.currentStep+1);
        return resource.save({ ...this.projeto } ).then((response) => {
          that.validaStepAndSave(response.id);
          that.currentStep = that.currentStep+1;
          that.projeto.id = response.id;
          this.$route.query['projetoId'] = response.id;
          return response;
        });
      },
      doConcludeProject() {
        const resource = this.resourceUrl;
        const that = this;
        this.projeto.status = 1;
        return resource.save({ ...this.projeto } ).then((response) => {
          that.validaStepAndSave(response.id);
          that.currentStep = that.currentStep+1;
          that.projeto.id = response.id;
          this.$route.query['projetoId'] = response.id;
          this.closeModal();
          return response;
        });
      },
      validaStepAndSave(projetoId) {
        if(this.currentStep == 0 && !this.projeto.id) {
          this.$router.push({ name: "projetos-captacao-edicao", query: { clientId: this.clientId, projetoId: projetoId }, params: { id: projetoId } });
        }
      },
      closeModal() {
        this.isVisibleModalProjectWarning = false
      }
    }
}
</script>

<style>

</style>
